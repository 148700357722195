import React from 'react'
import FileIcon, { defaultStyles } from 'react-file-icon'

function FileList ({ files }) {
  return (
    <ul className="list-none">
      {
        files.map((file, index) =>

          <li key={index}>
            <a href={file.path}>
              <span className="inline-block w-16 align-middle">
                <FileIcon
                  extension={file.extension}
                  { ...defaultStyles[file.extension] }
                />
              </span>
              {file.title}
            </a>
          </li>  

        )
      }
    </ul>
  )
}

export default FileList
