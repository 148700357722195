import React, { useState, useCallback } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import useSiteMetadata from '../queries/useSiteMetadata'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from "react-images"
import FileList from '../components/FileList'

function Page ({ data }) {
  const siteMetadata = useSiteMetadata()
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)
  
  const { wordpressPage: page } = data

  let files = null

  // page.acf.files is a set of files for /work-orders page, requires an acf repeater called 'files' with file sub-field called 'file'
  if (page.acf && page.acf.files) {

    files = page.acf.files.map(file => {
      return {
        title: file.file.title,
        extension: file.file.localFile.extension,
        path: file.file.localFile.localURL,
      }
    })

  }
  
  let photos = null

  // page.acf.pictures is the set of pictures on the /quilts page, requires an acf gallery called 'pictures'
  if (page.acf && page.acf.pictures) {

    photos = page.acf.pictures.map(picture => {
      const photo = picture.localFile.childImageSharp.fluid
      return {
        width: photo.presentationWidth,
        height: photo.presentationHeight,
        srcset: photo.srcSet,
        ...photo
      }
    })

  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }


  return (
    <Layout>
      <Helmet title={`${page.title} | ${siteMetadata.name}`} />
      <section>

        <h1 className="">
          {page.title === 'Home' ? 'Inthreadible Quilting' : page.title}
        </h1>

        <div className="flex flex-col sm:flex-row">

          <div
            className="flex-grow md:m-8"
            dangerouslySetInnerHTML={{ __html: page.content }}
          />

          {page.featured_media &&

            <div className="flex-grow rounded-lg overflow-hidden m-4 md:mx-8 lg:mx-12" style={{
              'minWidth': '300px',
            }}>
              <Img fluid={page.featured_media.localFile.childImageSharp.fluid} />
            </div>
          }

        </div>

        {files &&
        
          <FileList files={files} />

        }

        {photos &&

          <div>
            <Gallery
              photos={photos}
              onClick={openLightbox}
              targetRowHeight="200"
            />
            <ModalGateway>
              {viewerIsOpen ? (
                <Modal onClose={closeLightbox}>
                  <Carousel
                    currentIndex={currentImage}
                    views={photos.map(x => ({
                      ...x,
                      srcset: x.srcSet,
                      caption: x.title
                    }))}
                  />
                </Modal>
              ) : null}
            </ModalGateway>
          </div>
        }

      </section>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      acf {
        files {
          file {
            localFile {
              extension
              localURL
            }
            title
          }
        }
        pictures {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                src
                srcSet
                sizes
                presentationHeight
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`
