import { useStaticQuery, graphql } from "gatsby"

function useSiteMetadata() {
  const { allWordpressSiteMetadata } = useStaticQuery(
    graphql`
      query SiteMetadata {
        allWordpressSiteMetadata {
          edges {
            node {
              name
              description
            }
          }
        }
      }
    `
  )
  
  return allWordpressSiteMetadata.edges[0].node
}

export default useSiteMetadata
